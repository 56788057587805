import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dropdown } from '@naf/dropdown';
import { updateUrlQueryValues } from '../../../utils/updateUrlQueryValues';
import { sortByType } from '../../../../../types/sortByType';
import { actions as carFilterActions } from '../../../redux/modules/carFilter';
import { getURLParams } from '../../../lib/getUrlParams';

interface DropdownValue {
  label: string;
  value: sortByType;
  optionNumber: number;
}

const dropdownContent: Record<sortByType, DropdownValue> = {
  [sortByType.APPROVAL_DATE_DESCENDING]: {
    label: 'Sist testet',
    value: sortByType.APPROVAL_DATE_DESCENDING,
    optionNumber: 1,
  },
  [sortByType.MEASURED_RANGE_DESCENDING]: {
    label: 'Målt rekkevidde',
    value: sortByType.MEASURED_RANGE_DESCENDING,
    optionNumber: 3,
  },
  [sortByType.RANGE_DEVIATION_DESCENDING]: {
    label: 'Rekkevidde-avvik',
    value: sortByType.RANGE_DEVIATION_DESCENDING,
    optionNumber: 5,
  },
  [sortByType.CHARGING_SPEED_DESCENDING]: {
    label: 'Ladehastighet',
    value: sortByType.CHARGING_SPEED_DESCENDING,
    optionNumber: 7,
  },
  [sortByType.TRAILER_WEIGHT_DESCENDING]: {
    label: 'Tilhengervekt',
    value: sortByType.TRAILER_WEIGHT_DESCENDING,
    optionNumber: 9,
  },
  [sortByType.MAX_PAYLOAD_DESCENDING]: {
    label: 'Nyttelast',
    value: sortByType.MAX_PAYLOAD_DESCENDING,
    optionNumber: 11,
  },
};

const options = Object.values(dropdownContent).sort((e1, e2) => e1.optionNumber - e2.optionNumber);

const CarSortDropdown = () => {
  const [selectedValue, setSelectedValue] = useState<DropdownValue>(
    dropdownContent[sortByType.APPROVAL_DATE_DESCENDING],
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const parsedParams = getURLParams();
      if (typeof parsedParams.sortBy !== 'undefined') {
        setSelectedValue(dropdownContent[parsedParams.sortBy as sortByType]);
      }
    }
  }, []);

  const handleSelect = (value: DropdownValue) => {
    updateUrlQueryValues.updateSortByValue(value.value);
    dispatch(carFilterActions.refreshSearch());
    setSelectedValue(value);
  };

  return (
    <Dropdown
      width="200px"
      options={options}
      justify="right"
      selected={selectedValue}
      handleSelect={(value: DropdownValue) => handleSelect(value)}
    />
  );
};

export default CarSortDropdown;
