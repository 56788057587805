import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppInsightsContext, useTrackMetric } from '@microsoft/applicationinsights-react-js';
import useSelector from '../redux/typedHooks';
import { LayoutWithMainContent } from '../components/layout/Layout';
import { DEFAULT_IMG_URL } from '../lib/constants';
import { actions as carsActions } from '../redux/modules/cars';
import { useSendGTMEventOnce } from '../hooks/GTM/useSendGTMEventOnce';
import { NAFGTMEvent } from '../../../types/GTM/NAFGTMEvent';
import { ModelDetailPage } from './Elbilguiden/ModelDetailPage';
import createAPIQuery from '../lib/createURLQueries';
import { UrlParamsType } from '../../../types/urlParamsType';
import { InternalSearchCardType } from '../components/layout/InternalSearchMetaData';
import { useWindowLocation } from '../hooks/Window/useWindowLocation';
import { NotFound } from '../loadable-elements/NotFound';
import { useAuth0Token } from '../hooks/useAuth0Token';

interface Props {
  carSlug: string;
}

export const path = 'bilmodell/:carSlug';

const BilModell = ({ carSlug }: Props) => {
  const appInsights = useAppInsightsContext();
  useTrackMetric(appInsights, 'Bilmodell');
  const dispatch = useDispatch();
  const [isCarFetched, setIsCarFetched] = useState(false);
  const [isAdditionalModelsFetched, setIsAdditionalModelsFetched] = useState(false);
  const [didFetchMeta, setDidFetchMeta] = useState(false);
  const { simpleToken } = useAuth0Token();

  const cars = useSelector((state) => state.cars?.data?.[0] || []);
  const car = useSelector((state) => state.cars?.mappedData?.[carSlug] || null);

  const carModelState = useMemo(() => car || { data: undefined, meta: { isUpdating: true } }, [car]);
  const isUpdating = carModelState?.meta?.isUpdating ?? true;

  const { name, brandName, modelName, vehicleModelPictures, meta, id, slug, chassis } = carModelState.data || {};

  const isElectric = useSelector(
    (state) => state.cars?.mappedData?.[slug]?.data?.fuelCategory?.includes('Elektrisk') || false,
  );

  useEffect(() => {
    if (cars?.length < 10 && !isAdditionalModelsFetched && chassis) {
      const searchParams: UrlParamsType = {
        filter: {
          chassis,
          fuelCategories: ['elektrisk'],
        },
      };
      const queries = createAPIQuery(searchParams);
      dispatch(carsActions.getCars.request(queries));
      setIsAdditionalModelsFetched(true);
    }
  }, [cars, dispatch, isAdditionalModelsFetched, chassis]);

  useEffect(() => {
    if (!isCarFetched) {
      dispatch(carsActions.getOneCar.request(carSlug, { token: simpleToken }));
      setIsCarFetched(true);
    }
  }, [carSlug, isCarFetched, dispatch, simpleToken]);

  useEffect(() => {
    if (!didFetchMeta && carModelState?.data && slug && isElectric) {
      dispatch(carsActions.getCarModelMeta.request(slug, { slug, token: simpleToken }));
      setDidFetchMeta(true);
    }
  }, [carModelState, didFetchMeta, dispatch, simpleToken, slug, isElectric]);

  const displayName = name || (brandName && modelName ? `${brandName} ${modelName}` : null);
  const mainImg = vehicleModelPictures?.find((pic: any) => pic.type === 'largeFront') || null;
  const imgSrc = mainImg?.path ? `https:${mainImg.path}` : null;
  const seoDescription = meta?.seoConfig?.introduction || meta?.ingress || '';
  const seoTitle = meta?.seoConfig?.title || displayName || '';
  const windowPath = useWindowLocation();

  useSendGTMEventOnce(
    (carModelState?.data &&
      !isUpdating &&
      carModelState?.data?.fuelCategory &&
      carModelState?.data?.driveTrain && {
        event: NAFGTMEvent.bilmodell,
        motortype: carModelState?.data?.fuelCategory.join('/') || '',
        drivhjul: carModelState?.data?.driveTrain.join('/') || '',
      }) ||
      undefined,
    [carModelState],
  );

  if (!carModelState?.data && isCarFetched && !isUpdating) {
    return <NotFound />;
  }

  if (!isElectric) {
    return <NotFound />;
  }

  return (
    <LayoutWithMainContent
      title={displayName || 'Bilguiden'}
      description={seoDescription}
      url={windowPath}
      imgUrl={imgSrc || DEFAULT_IMG_URL}
      gtmArgs={{
        page_type: 'CarModelPage',
        pageCategory: displayName ? `Bilguiden/${displayName}` : '',
        contentId: id || '',
      }}
      seoDescription={seoDescription}
      seoTitle={seoTitle}
      isGuidePage={isElectric}
      internalSearchMetaData={{
        cardType: InternalSearchCardType.Car,
        brandName,
        carModelImage: imgSrc || undefined,
      }}
    >
      <ModelDetailPage slug={carSlug} />
    </LayoutWithMainContent>
  );
};

export default BilModell;
