import React, { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { Accordion } from '@naf/accordion';
import { Text, TextVariant } from '@naf/text';
import { useDispatch } from 'react-redux';
import { Checkbox, Form } from '@naf/checkbox';
import { breakpoints, spacing } from '@naf/theme';
import { nafColor } from '@nafcore/theme';
import { terms as T } from '../../../utils/bilguide';
import useSelector from '../../../redux/typedHooks';
import { FilterType, UrlParamsType } from '../../../../../types/urlParamsType';
import { actions as carFilterActions } from '../../../redux/modules/carFilter';
import { numberWithSpaces } from '../../../lib';
import { getURLParams } from '../../../lib/getUrlParams';
import { updateUrlQueryValues } from '../../../utils/updateUrlQueryValues';
import createAPIQuery from '../../../lib/createURLQueries';

interface Props {
  additionalFilters?: UrlParamsType['filter'];
}

interface CheckboxEvent {
  name: keyof FilterType;
  value: boolean;
}

const CarFilter = ({ additionalFilters }: Props) => {
  const dispatch = useDispatch();
  const [chassisVisible, setChassisVisible] = useState<number>(4);
  const [showExtraMobileFilters, setShowExtraMobileFilters] = useState(false);
  const [filter, setFilter] = useState<UrlParamsType['filter']>({});
  const { allFilters, filterNumber } = useSelector((state) => state.carFilter);
  const {
    chassis,
    priceGroup,
    trailerHitch,
    numberOfSeats: numberOfSeatsUnsorted,
    drivetrains: drivetrainsUnsorted,
    brands: brandsUnsorted,
  } = allFilters;

  const numberOfSeats = useMemo(
    () =>
      (numberOfSeatsUnsorted as { name: string; value: string; count: number }[])
        ?.slice()
        .sort((e1, e2) => e1.name.localeCompare(e2.name)) || [],
    [numberOfSeatsUnsorted],
  );

  const drivetrains = useMemo(
    () =>
      (drivetrainsUnsorted as { name: string; value: string; count: number }[])
        ?.slice()
        .sort((e1, e2) => e1.name.localeCompare(e2.name)) || [],
    [drivetrainsUnsorted],
  );

  const brands = useMemo(
    () =>
      (brandsUnsorted as { name: string; value: string; count: number }[])
        ?.slice()
        .sort((e1, e2) => e1.value.localeCompare(e2.value)) || [],
    [brandsUnsorted],
  );

  useEffect(() => {
    const parsedParams = getURLParams();
    const queries = createAPIQuery(parsedParams, additionalFilters);
    if (allFilters.length === 0) dispatch(carFilterActions.getCarArguments.request(queries));
  }, [dispatch, additionalFilters, allFilters.length]);

  const updateFilters = () => {
    const parsedParams = getURLParams();
    const queries = createAPIQuery(parsedParams, additionalFilters);
    dispatch(carFilterActions.getCarArguments.request(queries));
  };

  useEffect(() => {
    const parsedParams = getURLParams();
    if (typeof parsedParams.filter !== 'undefined') {
      setFilter(parsedParams.filter);
    } else {
      setFilter({});
    }
  }, [filterNumber]);

  const onShowMoreBtnClick = (filterType: string) => {
    switch (filterType) {
      case 'chassis':
        setChassisVisible(10);
        break;
      default:
        return null;
    }
    return null;
  };

  const showMoreFilters = () => {
    setShowExtraMobileFilters(!showExtraMobileFilters);
  };

  const checkedMap = useMemo(() => {
    if (!!chassis && !!priceGroup && !!numberOfSeats && !!drivetrains && !!filter && !!filterNumber) {
      return {
        chassis: chassis.reduce((acc: Record<string, boolean>, cur: { name: string; value: string }) => {
          if (filter && typeof filter.chassis !== 'undefined') {
            acc[cur.name] = filter.chassis.includes(cur.name);
          }
          return acc;
        }, {}),
        brands: brands.reduce((acc: Record<string, boolean>, cur: { name: string; value: string }) => {
          if (filter && typeof filter.brands !== 'undefined') {
            acc[cur.value] = filter.brands.includes(cur.value);
          }
          return acc;
        }, {}),
        priceGroup: priceGroup.reduce((acc: Record<string, boolean>, cur: { name: string; value: string }) => {
          if (filter && typeof filter.priceGroup !== 'undefined') {
            acc[cur.name] = filter.priceGroup.includes(cur.name);
          }
          return acc;
        }, {}),
        tested:
          filter && typeof filter.tested !== 'undefined' && filter.tested.length > 0 && filter.tested.includes('true'),
        trailerHitch:
          filter &&
          typeof filter.trailerHitch !== 'undefined' &&
          filter.trailerHitch.length > 0 &&
          filter.trailerHitch.includes('true'),
        numberOfSeats: numberOfSeats.reduce((acc: Record<string, boolean>, cur: { name: string; value: string }) => {
          if (filter && typeof filter.numberOfSeats !== 'undefined') {
            acc[cur.name] = filter.numberOfSeats.includes(cur.name);
          }
          return acc;
        }, {}),
        drivetrains: drivetrains.reduce((acc: Record<string, boolean>, cur: { name: string; value: string }) => {
          if (filter && typeof filter.drivetrains !== 'undefined') {
            acc[cur.name] = filter.drivetrains.includes(cur.name);
          }
          return acc;
        }, {}),
      };
    }
    return null;
  }, [filter, chassis, priceGroup, drivetrains, numberOfSeats, filterNumber, brands]);

  return (
    <Wrapper>
      {chassis && (
        <>
          <DesktopWrapper>
            <ExpandableListWrapper $direction="vertical">
              <Text tag="h3" variant={TextVariant.ArticleTextHeader}>
                Biltype
              </Text>
              {chassis.slice(0, chassisVisible).map((chassisType: { name: string; count: number }) => (
                <Checkbox
                  key={chassisType.name}
                  onChange={(e: CheckboxEvent) => {
                    updateUrlQueryValues.updateCombinedFilterValue('chassis', e.name);
                    dispatch(carFilterActions.refreshSearch());
                    updateFilters();
                  }}
                  name={chassisType.name}
                  label={`${chassisType.name} (${chassisType.count})`}
                  checked={checkedMap && checkedMap.chassis[chassisType.name]}
                  labelSize={TextVariant.BodyText}
                />
              ))}
              {chassis.length > chassisVisible && (
                <ShowMoreWrapper onClick={() => onShowMoreBtnClick('chassis')}>
                  &#43; <span>Vis flere</span>
                </ShowMoreWrapper>
              )}
            </ExpandableListWrapper>
          </DesktopWrapper>
          <MobileWrapper>
            <Accordion label="Biltype" size="medium">
              <AccordionContent>
                {chassis.map((chassisType: { name: string; count: number }) => (
                  <Checkbox
                    key={chassisType.name}
                    onChange={(e: CheckboxEvent) => {
                      updateUrlQueryValues.updateCombinedFilterValue('chassis', e.name);
                      dispatch(carFilterActions.refreshSearch());
                      updateFilters();
                    }}
                    name={chassisType.name}
                    label={`${chassisType.name} (${chassisType.count})`}
                    checked={checkedMap && checkedMap.chassis[chassisType.name]}
                  />
                ))}
              </AccordionContent>
            </Accordion>
          </MobileWrapper>
        </>
      )}
      {priceGroup && (
        <>
          <DesktopWrapper>
            <ExpandableListWrapper $direction="vertical">
              <Text tag="h3" variant={TextVariant.ArticleTextHeader}>
                {T.NEW_PRICE}
              </Text>
              {priceGroup.map((pg: any, i: number) => {
                const minPrice = pg.name.split('-')[0];
                const maxPrice = pg.name.split('-')[1];

                if (i === 0) {
                  return (
                    <Checkbox
                      onChange={(e: CheckboxEvent) => {
                        updateUrlQueryValues.updateExclusiveFilterValue('priceGroup', e.name);
                        dispatch(carFilterActions.refreshSearch());
                        updateFilters();
                      }}
                      key={pg.name}
                      name={pg.name}
                      label={`Under ${numberWithSpaces(maxPrice)} (${pg.count})`}
                      checked={checkedMap && checkedMap.priceGroup[pg.name]}
                      labelSize={TextVariant.BodyText}
                    />
                  );
                }
                if (i === priceGroup.length - 1) {
                  return (
                    <Checkbox
                      onChange={(e: CheckboxEvent) => {
                        updateUrlQueryValues.updateExclusiveFilterValue('priceGroup', e.name);
                        dispatch(carFilterActions.refreshSearch());
                        updateFilters();
                      }}
                      key={pg.name}
                      name={pg.name}
                      label={`Over ${numberWithSpaces(minPrice)} (${pg.count})`}
                      checked={checkedMap && checkedMap.priceGroup[pg.name]}
                      labelSize={TextVariant.BodyText}
                    />
                  );
                }

                return (
                  <Checkbox
                    key={pg.name}
                    onChange={(e: CheckboxEvent) => {
                      updateUrlQueryValues.updateExclusiveFilterValue('priceGroup', e.name);
                      dispatch(carFilterActions.refreshSearch());
                      updateFilters();
                    }}
                    name={pg.name}
                    label={`${numberWithSpaces(minPrice)} - ${numberWithSpaces(maxPrice)} (${pg.count})`}
                    checked={checkedMap && checkedMap.priceGroup[pg.name]}
                    labelSize={TextVariant.BodyText}
                  />
                );
              })}
            </ExpandableListWrapper>
          </DesktopWrapper>
          <MobileWrapper>
            <Accordion label={T.NEW_PRICE} size="medium">
              <AccordionContent>
                {priceGroup.map((pg: any, i: number) => {
                  const minPrice = pg.name.split('-')[0];
                  const maxPrice = pg.name.split('-')[1];

                  if (i === 0) {
                    return (
                      <Checkbox
                        onChange={(e: CheckboxEvent) => {
                          updateUrlQueryValues.updateExclusiveFilterValue('priceGroup', e.name);
                          dispatch(carFilterActions.refreshSearch());
                          updateFilters();
                        }}
                        key={pg.name}
                        name={pg.name}
                        label={`Under ${numberWithSpaces(maxPrice)} (${pg.count})`}
                        checked={checkedMap && checkedMap.priceGroup[pg.name]}
                      />
                    );
                  }
                  if (i === priceGroup.length - 1) {
                    return (
                      <Checkbox
                        onChange={(e: CheckboxEvent) => {
                          updateUrlQueryValues.updateExclusiveFilterValue('priceGroup', e.name);
                          dispatch(carFilterActions.refreshSearch());
                          updateFilters();
                        }}
                        key={pg.name}
                        name={pg.name}
                        label={`Over ${numberWithSpaces(minPrice)} (${pg.count})`}
                        checked={checkedMap && checkedMap.priceGroup[pg.name]}
                      />
                    );
                  }
                  return (
                    <Checkbox
                      key={pg.name}
                      onChange={(e: CheckboxEvent) => {
                        updateUrlQueryValues.updateExclusiveFilterValue('priceGroup', e.name);
                        dispatch(carFilterActions.refreshSearch());
                        updateFilters();
                      }}
                      name={pg.name}
                      label={`${numberWithSpaces(minPrice)} - ${numberWithSpaces(maxPrice)} (${pg.count})`}
                      checked={checkedMap && checkedMap.priceGroup[pg.name]}
                    />
                  );
                })}
              </AccordionContent>
            </Accordion>
          </MobileWrapper>
        </>
      )}
      <ExtraFiltersWrapper $showMobileFilters={showExtraMobileFilters}>
        <ExpandableListWrapper $direction="vertical">
          {trailerHitch && trailerHitch.length > 1 && (
            <CheckboxWrapper>
              <Checkbox
                key={`"trailerhitch-"${trailerHitch[0].count}`}
                onChange={(e: CheckboxEvent) => {
                  updateUrlQueryValues.updateCombinedFilterValue(e.name, 'true');
                  dispatch(carFilterActions.refreshSearch());
                  updateFilters();
                }}
                name="trailerHitch"
                label={`Tilhengerfeste (${trailerHitch[0].count})`}
                checked={!!checkedMap && checkedMap.trailerHitch}
                labelSize={TextVariant.BodyText}
              />
            </CheckboxWrapper>
          )}
        </ExpandableListWrapper>
        {brands && (
          <Accordion label="Merke" size="medium">
            <AccordionContent direction="vertical">
              {brands.map((brandsType: { value: string; name: string; count: number }) => (
                <Checkbox
                  key={`${brandsType.value}${brandsType.name}`}
                  onChange={(e: CheckboxEvent) => {
                    updateUrlQueryValues.updateCombinedFilterValue('brands', e.name);
                    dispatch(carFilterActions.refreshSearch());
                    updateFilters();
                  }}
                  name={brandsType.value}
                  label={`${brandsType.value} (${brandsType.count})`}
                  checked={checkedMap && checkedMap.brands[brandsType.value]}
                  labelSize={TextVariant.BodyText}
                />
              ))}
            </AccordionContent>
          </Accordion>
        )}
        {numberOfSeats && (
          <Accordion
            label="Antall seter"
            size="medium"
            openByDefault={filter && typeof filter.numberOfSeats !== 'undefined'}
          >
            <AccordionContent direction="vertical">
              {numberOfSeats.map((numberOfSeatsObject: { count: number; name: string; value: any }) => (
                <Checkbox
                  key={numberOfSeatsObject.name}
                  onChange={(e: CheckboxEvent) => {
                    updateUrlQueryValues.updateCombinedFilterValue('numberOfSeats', e.name);
                    dispatch(carFilterActions.refreshSearch());
                    updateFilters();
                  }}
                  name={numberOfSeatsObject.name}
                  label={`${numberOfSeatsObject.name} (${numberOfSeatsObject.count})`}
                  checked={!!checkedMap && checkedMap.numberOfSeats[numberOfSeatsObject.name]}
                  labelSize={TextVariant.BodyText}
                />
              ))}
            </AccordionContent>
          </Accordion>
        )}
        {drivetrains && (
          <Accordion label="Drivhjul" size="medium" openByDefault={filter && typeof filter.drivetrains !== 'undefined'}>
            <AccordionContent direction="vertical">
              {drivetrains.map((drivetrainsType: { name: string; value: string; count: number }) => (
                <Checkbox
                  key={drivetrainsType.name}
                  onChange={(e: CheckboxEvent) => {
                    updateUrlQueryValues.updateCombinedFilterValue('drivetrains', e.name);
                    dispatch(carFilterActions.refreshSearch());
                    updateFilters();
                  }}
                  name={drivetrainsType.name}
                  label={`${drivetrainsType.name} (${drivetrainsType.count})`}
                  checked={!!checkedMap && checkedMap.drivetrains[drivetrainsType.name]}
                  labelSize={TextVariant.BodyText}
                />
              ))}
            </AccordionContent>
          </Accordion>
        )}
      </ExtraFiltersWrapper>
      <ShowMoreFiltersWrapper onClick={showMoreFilters}>
        {!showExtraMobileFilters ? (
          <>
            &#43; <span>Vis flere filtre</span>
          </>
        ) : (
          <>
            - <span>Lukk filter</span>
          </>
        )}
      </ShowMoreFiltersWrapper>
    </Wrapper>
  );
};

const ExtraFiltersWrapper = styled.div<{ $showMobileFilters?: boolean }>`
  display: block;
  @media (max-width: ${breakpoints.m}) {
    max-height: 0;
    transition: max-height 0.5s ease-out;
    overflow: hidden;
    margin: 0 18px;
    form {
      margin-left: 0;
      margin-right: 0;
    }

    ${({ $showMobileFilters }) =>
      $showMobileFilters &&
      css`
        max-height: 10000px;
        transition: max-height 1s ease-in;
      `}
  }
`;

const DesktopWrapper = styled.div`
  display: block;
  @media (max-width: ${breakpoints.m}) {
    display: none;
  }
`;

const MobileWrapper = styled.div`
  display: none;
  @media (max-width: ${breakpoints.m}) {
    display: block;
    margin: 0 18px;
  }
`;

const ExpandableListWrapper = styled(Form)`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #c1c1c1;
  padding-bottom: 25px;

  h3:first-child {
    margin-bottom: 0;
  }

  @media (max-width: ${breakpoints.m}) {
    margin-left: ${spacing.space24};
    margin-right: ${spacing.space24};
  }
`;

const ShowMoreWrapper = styled.button`
  border: none;
  text-align: left;
  background: none;
  font-size: 1rem;
  padding: 0;
  margin-top: 17px;
  color: ${nafColor.signature.black} !important;

  &:hover,
  &:focus {
    color: ${nafColor.primary.park};
  }

  &:active,
  &:visited {
    color: ${nafColor.signature.black} !important;
  }

  span {
    text-decoration: underline;
  }

  &:hover,
  &:focus {
    cursor: pointer;
  }
`;

const ShowMoreFiltersWrapper = styled(ShowMoreWrapper)`
  display: none;
  @media (max-width: ${breakpoints.m}) {
    display: block;
    margin-left: ${spacing.space12};
    margin-right: ${spacing.space12};
  }
`;

const Wrapper = styled.div`
  p::first-letter {
    text-transform: uppercase;
  }
`;

const CheckboxWrapper = styled.div`
  padding-top: ${spacing.space24};
`;

const AccordionContent = styled(Form)`
  display: flex;
  flex-direction: column;
  margin: 0;
`;

export default CarFilter;
